// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
  
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

//custome
.panel-password {
  margin-top: 25px !important;
}

.panel-text-forget-password {
  text-align: right;
}

.group-btn-forgot-password {
  margin-top: 20px !important;
}

.btn-save-order {
  width: 100%;
  padding-top: 15px;
}

.total-price {
  margin-top: 15px !important;
}

.padding-left-0 {
  padding-left: 0px;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.order-info-seller {
  border: 2px solid #636262;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

.td-info-order {
  display: inline-flex;
  margin-bottom: 5px;
  width: 100%;
}

.td-info-order-area {
  margin-bottom: 10px;
}

.td-info-order-pending {
  margin-bottom: 5px;
  width: 100%;
}

.btn-edit-order-pending {
  float: right;
}

.pading-top-reson-pending {
  padding-top: 15px;
}

.content-info-order {
  margin-left: 15px;
  margin-bottom: 0px;
  word-break: break-all;
}
.order-code {
  display: inline-block !important;
  padding-left: 5px;
}
.b-name-seller {
  min-width: 42px;
}

.b-title-name-admin {
  min-width: 67px;
}

.div-history-change-status {
  border: 2px solid #636262;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 25px;
}

.text-input-price-pre-sale {
  input {
    text-align: right;
  }
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

.order-change-price-product-pre-sale {
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

//fix ant timeline item las
.ant-timeline {
  li:last-child {
    .ant-timeline-item-tail {
      display: none;
    }
    padding-bottom: 0px;
  }
}

// custome css for table list product and button offer, create order pending
.div-panel-product-offer {
  display: inline-block;
  width: 100%;
  padding-bottom: 5px;
  position: relative;
  .customLabel {
    display: block;
    position: absolute;
    bottom: 0;
  }
  .div-parent-button {
    display: block;
    width: auto;
    float: right;
    padding-right: 0px;
  }
  .btn-order-offer {
    display: inline-block;
    margin-right: 10px;
  }
  .btn-order-pending {
    display: inline-block;
  }
}

.panel-order-child {
  width: 100%;
  margin-top: 50px;
  .panel-a-order-child {
    width: 100%;
    margin-top: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid #636262;
  }
  .label-note {
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .panel-a-order-child:last-child {
    border-bottom: 0px solid #636262;
  }
}

// css custome form offer
.table-product-offer {
  padding-left: 12.5px;
  padding-right: 12.5px;

  .row-product-edit {
    input {
      text-align: right;;
    }
  }

  .ant-input-number-handler-wrap {
    display: none !important;
  }

  .group-total-addproduct {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
  }

  .display-block {
    display: inline-block;
    margin-bottom: 20px;
  }

  .sty-btn-add-product {
    display: block;
    float: right;
  }

  .txt-total-right {
    display: block;
    float: right;
  }
}

.group-btn-save-cancel {
  padding-left: 15px;
  padding-right: 15px;
}

.full-width {
  padding-left: 0px;
  padding-right: 0px;
}


//table product base of order
.div-panel-table {
  width: 100%;
  margin-top: 25px;
} 

.margin-bottom-15 {
  margin-bottom: 15px;
}

.cus-padding-left-0 {
  padding-left: 0px;
}

.cus-padding-right-0 {
  padding-right: 0px;
}

.history-change-product {
  font-weight: 500;
  font-style: italic;
}

.tr-right-input {
  input {
    text-align: right;
  }
}

.table-product-pending {
  display: block;
  .div-total-price {
    float: right;
    display: block;
    margin-top: 15px;
  }
  .input-total-price{
    display: inline-block;
    width: auto;
    text-align: right;
    margin-left: 5px;
  }
}

.a-linkcheck {
  margin-bottom: 5px;
  display: block;
}

.a-link-status {
  margin-left: 3px;
  display: inline-block !important;
}

.div-start-lock-chat {
  position: absolute;
  float: right;
  display: block;
  right: 15px;
}

.icon-chat-detail-order {
  font-size: 36px;
  color : #3699ff !important;
  margin-left: 15px;
  cursor: pointer;
}

.icon-chat-detail-order:hover {
  color : #2569af !important;
}

.span-max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.div-container-item-order {
  border-bottom: 2px solid #636262;
  margin-bottom: 50px;
}

@media (max-width: 1025px) {
  #customForm a:hover {
    color: #2d69a0;
    background: #3699FF;
  }
  
  #customForm a:active {
    color: #2d69a0;
    background: #3699FF;
  } 

  .panel-for-authen {
    margin-top: 0px !important;
  }
  
  #kt_quick_user_toggle {
    display: inline !important;
  }

  #kt_header_mobile_toggle {
    width: 0px;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .d-sm-inline {
    display: inline-block !important;
  }

  .d-none-mobile {
    display: none;
  }

  .panel-header-mobile {
    width: 100%;
    margin-top: 0px;
    ul {
      margin-top: 55px;
      padding-top: 0px;
      background: white;
      li {
        width: 100%;
        a {
          color: #1890ff;
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .link-logout {
        color: #1890ff;
      }
      .link-logout:active {
        color: #2d69a0;
        background: #3699FF;
      }
    }
  }
}

@media (min-width: 1025px) {
  .panel-header-mobile {
    display: none;
  }

  .panel-header {
    display: contents;
  }
}

.message-content span {
  white-space: pre-line
}

.date-picker-received {
  margin-left: 15px !important;
  height: 30px !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.column-bill-lstOrder {
  width: 300px;
}

.border-right-big {
  border-right: 2px solid #1d1d1f !important;
}

.span-load-more {
  font-size: 16px;
  margin-top: 10px;
  align-content: center;
  cursor: pointer;
  font-weight: 600;
  justify-content: center;
  text-decoration: underline;
}

.div-parent-loadmore {
  justify-content: center;
  display: flex;
}

.higthlight {
  background-color: #ffc800;
}

.higthlight-bottom {
  font-size: 14px;
  font-weight: 600;
}

.width-150 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  table-layout:fixed;
  overflow:hidden;
  word-wrap:break-word;
}

.width-100 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  table-layout:fixed;
  overflow:hidden;
  word-wrap:break-word;
}

.width-120 {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  table-layout:fixed;
  overflow:hidden;
  word-wrap:break-word;
}

.width-300 {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  table-layout:fixed;
  overflow:hidden;
  word-wrap:break-word;
}

// .name-column {
//   left: 0px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .bank-column {
//   left: 150px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .price-column {
//   left: 270px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .transfer-column {
//   left: 390px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .service-column {
//   left: 490px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .payment-column {
//   left: 610px;
//   position: -webkit-sticky;
//   position: sticky;
//   z-index: 10000;
//   background: inherit;
//   border-collapse: separate;
// }

// .table-bill {
//   width: max-content;
//   border-collapse: separate;
//   padding-left: 0 !important;
//   padding-right: 0 !important;
//   border-radius: 0 !important;
// }